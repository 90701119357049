import styled from "styled-components";
import Img from "./Img";

const App = () => {
    return (
        <Container>
            <div>
                <Img name="notice" alt="STRAW 서비스 종료 안내" />
            </div>
            <div>
                <Img name="payment" alt="결제 고객 안내" />
            </div>
        </Container>
    );
};

export default App;

const Container = styled.div`
    img {
        width: 100%;
    }

    @media screen and (min-width: 600px) {
        img {
            width: 600px;
            height: 670px;
        }
        div {
            text-align: center;
        }
    }

    @media screen and (min-width: 1200px) {
        display: flex;
        justify-content: space-between;
    }

    @media screen and (min-width: 1320px) {
        margin: 0 auto;
        width: 1320px;
    }

    @media screen and (min-height: 915px) {
        margin: 60px auto;
    }
`;
