import React from "react";

const Img = (props) => {
    const { name, type, ext: extInProps, alt } = props;
    const ext = type ? `.${extInProps}` : ".png";
    const src = `/images/${name}${ext}`;
    const srcSet = `/images/${name}${ext} 1x, /images/${name}@2x${ext} 2x, /images/${name}@3x${ext} 3x`;
    return <img src={src} srcSet={srcSet} {...props} alt={alt} />;
};

export default Img;
